<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActiveHistory }">
      <AddHistory v-if="addHistoryFormOpen" @addHistoryFormOpen="emitFormAddedHistory" />

      <transition name="slide-fade">
        <DailyReportForm v-if="formDailyReport" @close="formDailyReport = false" :champaResigned="propsChampaResigned" />
      </transition>
      <div class="page-header" v-if="!formActiveHistory">
        <div class="page-header-start">
          <h3 v-if="$route.query.type === 'champa'">Champa - History</h3>
          <h3 v-else>BA - History</h3>
          <div class="button-group">
            <button class="button primary sm" @click="addNewHistory()">
              Add History
            </button>

            <!-- button single date export champa in history  -->
            <button v-if="$route.query.type === 'champa'" class="button light-grey sm" @click="
              formActiveExport = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  champa: 'champa',
                },
              })
              .catch((err) => { });
            ">
              Export Champa History
            </button>

            <button v-else class="button light-grey sm" @click="
              formActiveExport = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  BA: 'BA',
                },
              })
              .catch((err) => { });
            ">
              Export BA History
            </button>




            <!-- Export Outlet History -->
            <button class="button light-grey sm" @click="ExportOutletHistory">
              Export All Outlet History
            </button>
          </div>
        </div>




        <div class="page-header-end">
          <input v-model="searchResigned" type="text" class="input" placeholder="Search..." />
        </div>
        <div class="page-header-end">
          <ul>
            <li v-if="isLoading" class="loading_nav"></li>
            <li v-else>
              <p>All Champa:</p>
              <h3>{{ totalChampa }}</h3>
            </li>
            <li>
              <div class="select">
                <filterSelect v-model="selectedSupervisor" :data="supervisor" itemName="name" optionName="Supervisor" />
              </div>
            </li>
            <!-- <li>
              <div class="select">
                <filterSelect v-model="selectedStatus" :data="status" />
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- <p v-if="indValue !== null">{{ champaResigned[indValue].joinDate }}</p> -->

      <!-- {{ indValue }}
      {{ date }} -->


      <div v-if="isLoading" class="table-container scrollable">
        <div v-for="(l, indexLoading) in champaResigned" :key="indexLoading" class="loading"></div>
      </div>
      <div v-else class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs ">Champa Name</th>
              <th class="">Working ID</th>
              <th class="">Join Date</th>
              <th class="">Resigned Date</th>
              <th class="is-xs text-center">Outlet Code</th>
              <th>Outlet Name</th>
              <th>DailyReport</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in champaResigned" :key="index">
              <td class="clickable" @click="formActiveHistory = true;
              $router.push({
                query: {
                  selectedSupervisor: $route.query.selectedSupervisor,
                  selectedStatus: $route.query.selectedStatus,
                  page: $route.query.page,
                  perPage: pages.perPage,
                  searchResigned: $route.query.searchResigned,
                  type: $route.query.type,
                  champaHistory: $route.query.champaHistory,
                  historyId: i._id,
                }
              })
                .catch((err) => { });">
                {{ i.employeeID }}
              </td>
              <td>{{ i.fullname }}</td>
              <!-- Working ID start -->
              <td class="has-edit">
                <a class="clickableSomefield" v-if="!editWorkingToggle" @click="ClickWorkingID(i._id, i, index)">
                  {{ i.workingID }}
                  <i class="fa-solid fa-pen"></i>
                </a>

                <div class="input-toggle" v-else-if="indValue === index">
                  <input type="text" class="input" v-model="i.workingID" />
                  <i class="fa-solid fa-floppy-disk" @click="UpdateWorkingIDHistory()"></i>
                </div>
                <a v-else class="clickableSomefield">
                  {{ i.workingID }}
                </a>
              </td>
              <!-- Working ID end -->
              <!-- Join Date start -->
              <td>
                <a class="clickableSomefield" @click="
                  ClickPropSingleDatePicker(i.joinDate, i._id, 'JoinDate')
                  ">
                  {{ getMoment(i.joinDate) }}
                  <i class="fa-solid fa-pen"></i>
                </a>
              </td>
              <!-- Join Date end -->
              <!-- Resigned Date start -->
              <td>
                <a class="clickableSomefield" @click="
                  ClickPropSingleDatePicker(
                    i.resignedDate,
                    i._id,
                    'ResignedDate'
                  )
                  ">
                  {{ getMoment(i.resignedDate) }}
                  <i class="fa-solid fa-pen"></i>
                </a>
              </td>
              <!-- Resigned Date end -->
              <!-- Outlet Code start -->
              <td class="text-center">{{ i.shopCode }}</td>

              <!-- <td @click="ClickFormStatusLockOutlet(i.shopId)">
                <a class="clickableSomefield">
                  {{ i.shopName }}
                  <i class="fa-sharp fa-solid fa-clock-rotate-left"></i></a>
              </td> -->

              <td>
                {{ i.shopName }}
              </td>
              <td>
                <a class="clickableSomefield" @click="clickFormDailyReport(i)">
                  <i class="fa-solid fa-plus"></i> Daily Report
                </a>
              </td>
              <!-- Daily Report end -->
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>

        <SingleDatePicker v-if="isSingleDatePicker" @close="emitCloseSingleDatePicker" :propsDate="date"
          @emitDate="emitSingleDatePicker" />
      </div>

      <paginate v-model="page" :total="all" />
    </div>


    <!-- <transition name="slide-fade">
      <OutletFormStatusLock v-if="formActiveDailyReport" :is="'OutletFormStatusLock'"
        @closeForm="formActiveDailyReport = false" />
    </transition> -->


    <transition name="slide-fade">
      <HistoryForm v-if="formActiveHistory" @closeFormHistory="formActiveHistory = false" />
    </transition>





    <export-date v-if="formActiveExport" @closeExport="formActiveExport = false" />
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import filterSelect from "../components/filter/filter.vue";
import OutletFormStatusLock from "../components/form/outlet-form-statusLock.vue";
import HistoryForm from "../components/form/history.vue";
import DailyReportForm from "../components/form/daily-report";
import moment from "moment";
import AddHistory from "../components/form/add-history.vue";
import SingleDatePicker from "../components/singleDatePicker.vue";
import ExportDate from "../components/export/export-date.vue";
export default {
  components: {
    paginate,
    filterSelect,
    OutletFormStatusLock,
    HistoryForm,
    DailyReportForm,
    AddHistory,
    SingleDatePicker,
    ExportDate,


  },
  data: () => ({
    status: [
      {
        _id: true,
        name: "Working",
      },
      {
        _id: false,
        name: "Resigned",
      },
    ],
    addHistoryFormOpen: false,
    formDailyReport: false,
    editWorkingToggle: false,
    indValue: null,
    isLoading: false,
    isCheckAddedHistory: false,
    formActiveDailyReport: false,
    formActiveHistory: false,
    formActiveExport: false,
    isSingleDatePicker: false,
    champaResigned: [],
    supervisor: [],
    // mapHistory: "",
    oldWorkingID: "",
    date: "",
    updateForm: "",

    selectedSupervisor: "",
    selectedStatus: "",
    searchResigned: "",
    type: "",
    totalChampa: "",
    propsChampaResigned: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    timeout: null,
  }),
  watch: {
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchChampaResigned();

        if (this.$route.query.historyId) {
          // FetchHistoryId();
        }
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: {
            ...this.$route.query,
            selectedSupervisor: val,
          },
        });
      },
    },
    // type
    "$route.query.type": {
      handler(val) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.FetchChampaResigned();
        }, this.$timeout);
      },
      immediate: true,
    },
    type: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, type: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchChampaResigned();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    // "$route.query.searchResigned": {
    //   async handler(val) {
    //     this.isLoading = true;
    //     await this.FetchChampaResigned();

    //     setTimeout(() => {
    //       this.isLoading = false;
    //     }, 2000);
    //   },
    //   immediate: true,
    // },

    searchResigned(newValue) {
      // handler(val) {
      //   this.$router.push({
      //     ...this.$route,
      //     query: { ...this.$route.query, searchResigned: val },
      //   });
      // },

      this.isLoading = true;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$router
          .push({
            ...this.$route,
            query: { ...this.$route.query, searchResigned: newValue },
          })
          .catch((err) => { });

        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }, 3000);
    },
    isCheckAddedHistory(val) {
      if (val === true) {
        this.FetchChampaResigned();
      }
    },
    $route() {
      this.FetchChampaResigned();
    },

    indValue(newValue, oldValue) {
      if (newValue) {
        this.indValue = newValue;
      }
    },
  },
  computed: {
    getMoment() {
      return (val) => {
        if (val) return moment(val).format("DD-MMM-YY");
      };
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }

    // if (this.$route.query.historyId) {
    //   FetchHistoryId();
    // }
  },
  created() {
    this.FetchSupervisor();
  },
  methods: {
    addNewHistory() {
      this.addHistoryFormOpen = !this.addHistoryFormOpen;
    },
    clickFormDailyReport(val) {
      this.formDailyReport = true;
      this.propsChampaResigned = val;
    },

    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchChampaResigned() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-champa-resigned?admin=${this.$route.query.selectedSupervisor}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchResigned}&typeChampa=${this.$route.query.type}`
      );
      this.champaResigned = res.data.aggreShop;
      this.totalChampa = res.data.total;
      this.all = res.data.total;
    },
    // async FetchHistoryId() {
    //   const res = await this.$axios.get(
    //     `${this.$server}api/history-find-id/${this.route.query.historyId}`
    //   );
    //   this.mapHistory = res.mapHistory;
    //   // this.oldWorkingID = mapHistory.workingID
    // },
    ClickWorkingID(valueId, value, index) {
      this.$router
        .push({
          ...this.$route,
          query: { ...this.$route.query, historyId: valueId },
        })
        .catch((err) => { });

      this.oldWorkingID = value.workingID;
      this.indValue = index;
      this.editWorkingToggle = !this.editWorkingToggle;
    },
    ClickPropSingleDatePicker(valDate, valId, status) {
      this.$router
        .push({
          ...this.$route,
          query: { ...this.$route.query, historyId: valId, statusDate: status },
        })
        .catch((err) => { });
      this.isSingleDatePicker = !this.isSingleDatePicker;
      this.date = valDate;
      // console.log(valDate);
    },
    ClickFormStatusLockOutlet(valueId) {
      this.$router
        .push({
          ...this.$route,
          query: { ...this.$route.query, shopId: valueId },
        })
        .catch((err) => { });
      this.formActiveDailyReport = true;
    },

    //
    //---- start -----
    //api ດຽວກັນ
    async UpdateJoinResignDate(objUpdateForm) {
      // console.log(objUpdateForm);
      try {
        await this.$swal({
          title: "ຕ້ອງການແກ້ໄຂຂໍ້ມູນນີ້ແທ້ບໍ່?",
          text: moment(this.date).format("DD-MMM-YY"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ຢືນຢັນ",
          cancelButtonText: "ຍົກເລີກ",
        }).then(async (result) => {
          if (result.isConfirmed) {
            //
            //Alert Loading
            this.$swal({
              title: "Loading...",
              html: "Please wait",
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })

            await this.$axios
              .put(`${this.$server}api/history-update-workingID`, objUpdateForm)
              .then(() => {
                setTimeout(() => {
                  this.isSingleDatePicker = false;
                  this.$swal({
                    position: "top",
                    icon: "success",
                    title: "Success",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  if (this.$route.query.type === "champa") {
                    this.$router.push({
                      query: {
                        selectedSupervisor: this.$route.query.selectedSupervisor,
                        selectedStatus: this.$route.query.selectedStatus,
                        page: this.$route.query.page,
                        perPage: 10,
                        searchResigned: this.$route.query.searchResigned,
                        type: "champa",
                        champaHistory: "champaHistory",
                      },
                    });
                    // console.log("champa");
                  } else if (this.$route.query.type === "BA") {
                    this.$router.push({
                      query: {
                        selectedSupervisor: this.$route.query.selectedSupervisor,
                        selectedStatus: this.$route.query.selectedStatus,
                        page: this.$route.query.page,
                        perPage: 10,
                        searchResigned: this.$route.query.searchResigned,
                        type: "BA",
                        baHistory: "baHistory",
                      },
                    });
                    // console.log("ba");
                  }

                  this.date = "";
                }, 500);
              });
          }
        });
      } catch (error) { }
    },
    async UpdateWorkingIDHistory() {
      try {
        await this.$swal({
          title: "ຕ້ອງການແກ້ໄຂຂໍ້ມູນນີ້ແທ້ບໍ່?",
          text: this.champaResigned[this.indValue].workingID,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ຢືນຢັນ",
          cancelButtonText: "ຍົກເລີກ",
        }).then(async (result) => {
          if (result.isConfirmed) {
            //
            //Alert Loading
            this.$swal({
              title: "Loading...",
              html: "Please wait",
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })

            const updateForm = {
              id: this.$route.query.historyId,
              workingID: this.champaResigned[this.indValue].workingID,
            };
            await this.$axios
              .put(`${this.$server}api/history-update-workingID`, updateForm)
              .then(() => {
                setTimeout(() => {
                  this.editWorkingToggle = !this.editWorkingToggle;
                  this.$swal({
                    position: "top",
                    icon: "success",
                    title: "Success",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  if (this.$route.query.type === "champa") {
                    this.$router.push({
                      query: {
                        selectedSupervisor: this.$route.query.selectedSupervisor,
                        selectedStatus: this.$route.query.selectedStatus,
                        page: this.$route.query.page,
                        perPage: 10,
                        searchResigned: this.$route.query.searchResigned,
                        type: "champa",
                        champaHistory: "champaHistory",
                      },
                    });
                    // console.log("champa");
                  } else {
                    this.$router.push({
                      query: {
                        selectedSupervisor: this.$route.query.selectedSupervisor,
                        selectedStatus: this.$route.query.selectedStatus,
                        page: this.$route.query.page,
                        perPage: 10,
                        searchResigned: this.$route.query.searchResigned,
                        type: "BA",
                        baHistory: "baHistory",
                      },
                    });
                    // console.log("ba");
                  }
                }, 500);
              });
          } else {
            this.editWorkingToggle = !this.editWorkingToggle;
            this.champaResigned[this.indValue].workingID = this.oldWorkingID;
            this.indValue = null;
            if (this.$route.query.type === "champa") {
              this.$router.push({
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchResigned: this.$route.query.searchResigned,
                  type: "champa",
                  champaHistory: "champaHistory",
                },
              });
              // console.log("champa");
            } else {
              this.$router.push({
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchResigned: this.$route.query.searchResigned,
                  type: "BA",
                  baHistory: "baHistory",
                },
              });
              // console.log("ba");
            }
          }
        });
      } catch (error) { }
    },
    //
    //
    //----- end ------

    //
    //
    //value ທີ່ໄດ້ຈາກການ emit
    emitFormAddedHistory(val) {
      val[0] = false; //ຫຼັງຈາກໄດ້ຄ່າຈາກການ emit set [0] = false ເພື່ອປິດຟອມ add history
      this.addHistoryFormOpen = val[0];

      if (val[1] === "addedHistory") {
        val[1] = true; //ຫຼັງຈາກໄດ້ຄ່າຈາກການ emit set [1] = true ເພື່ອ fetch ຂໍ້ມູນໃໝ່ champa history
        this.isCheckAddedHistory = val[1];
        setTimeout(() => {
          this.isCheckAddedHistory = false;
        }, 1000);
      }
    },
    emitCloseSingleDatePicker() {
      this.isSingleDatePicker = false;
      if (this.$route.query.type === "champa") {
        this.$router
          .push({
            query: {
              selectedSupervisor: this.$route.query.selectedSupervisor,
              selectedStatus: this.$route.query.selectedStatus,
              page: this.$route.query.page,
              perPage: 10,
              searchResigned: this.$route.query.searchResigned,
              type: "champa",
              champaHistory: "champaHistory",
            },
          })
          .catch((err) => { });
      } else {
        this.$router
          .push({
            query: {
              selectedSupervisor: this.$route.query.selectedSupervisor,
              selectedStatus: this.$route.query.selectedStatus,
              page: this.$route.query.page,
              perPage: 10,
              searchResigned: this.$route.query.searchResigned,
              type: "BA",
              baHistory: "baHistory",
            },
          })
          .catch((err) => { });
      }

      this.date = "";
    },
    emitSingleDatePicker(val) {
      this.date = val;
      if (this.$route.query.status === "Success") {
        if (this.$route.query.statusDate === "JoinDate") {
          const updateForm = {
            id: this.$route.query.historyId,
            joinDate: this.date,
          };
          this.UpdateJoinResignDate(updateForm);
        } else if (this.$route.query.statusDate === "ResignedDate") {
          const updateForm = {
            id: this.$route.query.historyId,
            resignedDate: this.date,
          };
          this.UpdateJoinResignDate(updateForm);
        }
      }
      this.date = "";
    },

    //
    //Export Outlet History
    async ExportOutletHistory() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-shop-history`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OutletHistory.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          // this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clickableSomefield {
  border: 1px solid var(--border-color);
  background-color: var(--light-grey-color);
  font-weight: normal;
  padding: 5px 8px;

  &:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
  }
}

td.has-edit {
  position: relative;

  p {
    display: flex;
    align-items: center;

    &:hover {
      color: var(--primary-color);
    }

    i {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
      font-size: var(--xxsm-font);
    }
  }

  .input-toggle {
    display: flex;
    align-items: center;
    z-index: 0;
    min-width: 130px;

    input {
      height: 2rem;
      font-size: var(--xxsm-font);
      border-radius: 0 !important;
    }

    i {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
      color: #fff;
      height: 2rem !important;
      font-size: var(--xxsm-font);
    }
  }
}

.table-container {
  padding: 0 10px 0 0;

  .table {
    width: 100%;
  }

  .table tr {

    &:hover td,
    th {
      color: unset;
    }

    th {
      font-size: var(--xsm-font);
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: #fff;
      border-bottom: 1px solid var(--border-color);
    }

    th,
    td {
      font-size: var(--xsm-font);
    }
  }
}
</style>
