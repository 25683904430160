<template>
  <section class="page-container">
    <div class="page-content medium">
      <div class="page-header">
        <div class="page-header-start">
          <h3>Salary</h3>
        </div>
      </div>
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th class="is-xs">Month</th>
              <th class="is-xs">No. Employee</th>
              <th class="text-right">SSO</th>
              <th class="text-right">TAX</th>
              <th class="text-right">Net Salary</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in 10" :key="(n = index)">
              <td>{{ index + 1 }}/2022</td>
              <td>142</td>
              <td class="text-right">5,717,886</td>
              <td class="text-right">6,237,692</td>
              <td class="text-right">874,412.25</td>
              <td class="text-right">187,375,009.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <salary-detail />
  </section>
</template>

<script>
import salaryDetail from "../components/salary-detail.vue";
export default {
  components: { salaryDetail },
};
</script>

<style lang="scss" scoped>
.control {
  display: flex;
  button {
    margin-left: 20px;
  }
}

ul {
  display: flex;
  li {
    padding: 10px;
  }
}
.table-container {
  height: 100%;
}
.input {
  font-size: 16px;
  background-color: transparent;
  border-width: 0 !important;
  background-color: var(--light-grey-color);
}
</style>
