<template>
  <ValidationObserver v-slot="{ handleSubmit }" class="sideform" tag="div">
    <div class="sideform-header has-image">
      <div class="champa-image">
        <div class="file">
          <label class="file-label">
            <input class="file-input" type="file" @change="onFileChange" ref="fileInput" />
            <!-- add image background in span -->
            <span v-if="image" class="file-cta" :style="{
              backgroundImage: 'url(' + $server + image + ')',
            }"></span>
            <div v-else class="file-cta"></div>
          </label>
        </div>
      </div>
      <div class="sideform-header-right">
        <h3>{{ champa.fullname }}</h3>
        <ul>
          <li v-if="$route.query.id && champa.status === 'working'" @click="
            openModal = true;
          $router
            .push({
              ...$route,
              query: {
                ...$route.query,
                resigned: newHistoryId,
              },
            })
            .catch((err) => { });
          ">
            Status :
            <span style="color: red">{{ champa.status }}</span>
          </li>
          <li v-else @click="openModal = true">
            Status :
            <span>{{ champa.status }}</span>
          </li>
          <li>
            Employee ID : <span>{{ champa.employeeID }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="form scrollable-auto">
      <div class="grids is-2-desktop gap-10-desktop">
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">Champa Fullname</label>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <div class="control">
                <input v-model="newFullName" placeholder="First name and Last name" type="text" class="input"
                  :class="{ errorText: errors[0] }" required />
              </div>
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
              <!-- message error -->
              <span class="error is-error" v-else-if="errorMessage === 'duplicate name'">{{ errorMessage }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Employee ID</label>
            <div class="control">
              <input v-model="newEmployeeID" type="text" class="input" placeholder="Employee ID" disabled />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Nick Name</label>
            <div class="control">
              <input v-model="newNickName" type="text" class="input" placeholder="Nickname" />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Name</label>
            <div class="control">
              <div class="select">
                <select v-model="newShopId" disabled>
                  <option v-for="(n, nameIdx) in shop" :key="nameIdx" :value="n._id">
                    {{ n.name }}
                  </option>
                </select>
              </div>
              <!-- <div>
                <selectsingle v-model="newShopId" :items="shop" single />
              </div> -->
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Code</label>
            <div class="control">
              <div class="select">
                <select v-model="newShopId" disabled>
                  <option v-for="(s, idx) in shop" :key="idx" :value="s._id">
                    {{ s.shopCode }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">DOB</label>
            <div class="control">
              <date-picker v-model="newBirthDate" type="date" placeholder="Select Date">
              </date-picker>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Bank Account Name</label>
            <div class="control">
              <input v-model="newAccountName" type="text" class="input" placeholder="Bank account name" />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Bank Account Number</label>
            <div class="control">
              <input v-model="newAccountNumber" type="text" class="input" placeholder="Bank account no." />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">SSO ID</label>
            <div class="control">
              <input v-model="newSSOID" type="text" class="input" placeholder="SSO ID" />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Mobile Number</label>
            <div class="control">
              <input v-model="newTel" type="text" class="input" placeholder="Mobile No." />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Vaccinate</label>
            <div class="control">
              <div>
                <selectsingle v-model="newVaccines" :items="vaccinate" single itemName="name" />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Password</label>
            <ValidationProvider slim v-slot="{ errors }">
              <!-- rules="required" -->
              <div class="control">
                <input v-if="$route.query.id" v-model="password" type="text" class="input" placeholder="********"
                  disabled />
                <input v-else v-model="password" type="text" class="input" placeholder="********"
                  :class="{ errorText: errors[0] }" />
                <!-- required -->
              </div>
              <span class="error is-error" v-if="errors[0]">
                please enter information
              </span>
              <!-- message error -->
              <span class="error is-error" v-else-if="errorMessage === 'duplicate password'">{{ errorMessage }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Champa Type</label>
            <div class="control">
              <div class="select">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <select v-model="typeChampa" :class="{ errorText: errors[0] }" required>
                    <option value="champa" selected>Champa</option>
                    <option value="BA">BA</option>
                  </select>
                  <span class="error is-error" v-if="errors[0]">
                    please select type
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Address</label>
            <div class="control">
              <input v-model="newAddress" type="text" class="input" placeholder="Address" />
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Supervisor</label>
            <div class="control">
              <!-- <div class="select" v-if="$route.query.id">
                <select v-model="newSupervisorId" disabled>
                  <option
                    v-for="(s, index) in surpervisor"
                    :key="index"
                    :value="s._id"
                  >
                    {{ s.name }}
                  </option>
                </select>
              </div> -->
              <div class="select">
                <select v-model="newShopId" disabled>
                  <option v-for="(s, idx) in shop" :key="idx" :value="s._id">
                    {{ s.adminName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grids is-1-desktop gap-10-desktop">
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">Note</label>
            <div class="control">
              <!-- <input v-model="newFreeText" type="text" class="input" /> -->
              <textarea class="textarea" rows="5" v-model="newFreeText"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sideform-footer">
      <button v-if="$route.query.id" class="button primary" @click="handleSubmit(UpdateChampa)">
        Update
      </button>
      <button v-else class="button primary" @click="handleSubmit(AddChampa)">
        Save
      </button>
      <button class="button grey" @click="close">Cancel</button>
    </div>

    <assginedOutlet v-if="openModal" @closeModal="openModal = false" />
  </ValidationObserver>
</template>

<script>
import DatePicker from "vue2-datepicker";
import assginedOutlet from "../form/assigned.vue";
import "vue2-datepicker/index.css";
import selectsingle from "@coms/selectsingle-search.vue";
export default {
  data: () => ({
    formActive: false,
    openModal: false,
    surpervisor: [],
    shop: [],
    // mapShop: [],
    champa: {},
    newEmployeeID: "",
    newFullName: "",
    newNickName: "",
    image: "",
    newShopId: "",
    newShopName: "",
    newShopCode: "",
    newAccountName: "",
    newAccountNumber: "",
    newBirthDate: "",
    newSSOID: "",
    newVaccines: "",
    newTel: "",
    password: "",
    newStatus: "",
    newAddress: "",
    newSupervisorId: "",
    typeChampa: "",
    typeId: "",
    newFreeText: "",
    newHistoryId: "",
    errorMessage: "",

    vaccinate: [
      {
        _id: "dose0",
        name: "dose0",
      },
      {
        _id: "dose1",
        name: "dose1",
      },
      {
        _id: "dose2",
        name: "dose2",
      },
      {
        _id: "dose3",
        name: "dose3",
      },
    ],
  }),
  components: {
    DatePicker,
    selectsingle,
    assginedOutlet,
  },
  created() {
    this.FetchSupervisor();
    this.IsCheckLady();
  },
  watch: {
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.FetchChmapaId();
          this.FetchSupervisor();
          this.IsCheckLady();
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$router
        .push({
          query: {
            selectedSupervisor: this.$route.query.selectedSupervisor,
            selectedStatus: this.$route.query.selectedStatus,
            page: this.$route.query.page,
            perPage: 10,
            searchChampa: this.$route.query.searchChampa,
            type: this.$route.query.type,
          },
        })
        .catch((err) => { });
    },
    onFileChange($event) {
      const file = $event.target.files[0];
      this.uploadImage(file);
    },
    async uploadImage(file) {
      const fd = new FormData();
      fd.append("image", file);
      const res = await this.$axios.post(`${this.$server}api/uploadimage`, fd);
      this.image = res.data.link;
    },
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.surpervisor = res.data.mapAdmin;
    },
    async IsCheckLady() {
      const res = await this.$axios.get(
        `${this.$server}api/isCheck-user?userId=${this.$route.query.id}`
      );
      this.shop = res.data.filterShop;
    },
    FetchChmapaId() {
      this.$axios
        .get(`${this.$server}api/user-findid/` + this.$route.query.id)
        .then((res) => {
          this.champa = res.data.findIdUser;
          this.newEmployeeID = this.champa.employeeID;
          this.newFullName = this.champa.fullname;
          this.newNickName = this.champa.nickname;
          this.newShopId = this.champa.shopId;
          this.newShopName = this.champa.shopName;
          this.newShopCode = this.champa.shopCode;
          this.image = this.champa.image;
          this.newAccountName = this.champa.accountName;
          this.newAccountNumber = this.champa.accountNumber;
          this.newBirthDate = new Date(this.champa.birthDate);
          this.newSSOID = this.champa.ssoID;
          this.newVaccines = this.champa.vaccines;
          this.newTel = this.champa.tel;
          this.password = this.champa.password;
          this.newStatus = this.champa.status;
          this.newAddress = this.champa.address;
          this.newSupervisorId = this.champa.adminId;
          this.typeId = this.champa.typeId;
          this.newHistoryId = this.champa.historyId;
          this.typeChampa = this.champa.typeChampa;
          this.newFreeText = this.champa.freeText;
        });
    },
    async AddChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        await this.$axios
          .post(`${this.$server}api/user-add`, {
            employeeID: this.newEmployeeID,
            fullname: this.newFullName,
            nickname: this.newNickName,
            image: this.image,
            shopId: this.newShopId,
            accountName: this.newAccountName,
            accountNumber: this.newAccountNumber,
            birthDate: this.newBirthDate,
            ssoID: this.newSSOID,
            vaccines: this.newVaccines,
            tel: this.newTel,
            password: this.password,
            address: this.newAddress,
            typeChampa: this.typeChampa,
            freeText: this.newFreeText,
          })
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });
              this.$emit("close");
              this.$router
                .push({
                  query: {
                    selectedSupervisor: this.$route.query.selectedSupervisor,
                    selectedStatus: this.$route.query.selectedStatus,
                    page: this.$route.query.page,
                    perPage: 10,
                    searchChampa: "",
                    type: this.$route.query.type,
                  },
                })
                .catch((err) => { });
            }, 500);

          });
      } catch ({ response }) {
        const errorText = response.data.message;
        switch (errorText) {
          case "this password is used":
            return (this.errorMessage = "duplicate password");
          case "unique fullname":
            return (this.errorMessage = "duplicate name");
        }
      }
    },
    async UpdateChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        const updateChampa = {
          id: this.typeId,
          employeeID: this.newEmployeeID,
          fullname: this.newFullName,
          nickname: this.newNickName,
          image: this.image,
          accountName: this.newAccountName,
          accountNumber: this.newAccountNumber,
          birthDate: this.newBirthDate,
          ssoID: this.newSSOID,
          vaccines: this.newVaccines,
          tel: this.newTel,
          address: this.newAddress,
          typeChampa: this.typeChampa,
          freeText: this.newFreeText,
        };
        await this.$axios
          .put(`${this.$server}api/user-update`, updateChampa)
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });
              this.$emit("close");
              this.$router.push({
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchChampa: this.$route.query.searchChampa,
                  type: this.$route.query.type,
                },
              });
            }, 500);
          });
      } catch ({ response }) {
        const errorText = response.data.message;
        switch (errorText) {
          case "unique fullname":
            return (this.errorMessage = "duplicate name");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-cta {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-image: url("../../assets/female-avatar.png");
  background-size: cover;
  background-position: center;
}

ul {
  li {
    cursor: pointer;
  }
}

.file-input {
  display: none;
}
</style>
