<template>
  <div class="modal">
    <div class="modal-background"></div>

    <ValidationObserver v-slot="{ handleSubmit }" class="modal-card" tag="div">
      <div class="modal-card">
        <div class="card-header">
          <h3>Add History</h3>
          <i class="fa-regular fa-times close" @click="close()"></i>
        </div>

        <div class="card-body">

          <!-- Select Outlet -->
          <div class="field">
            <label for="" class="label">Select Outlet</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div>
                  <selectsingleOutlet v-model="outletId" :items="allOutlet" itemName="name" single
                    :class="{ errorText: errors[0] }" required @valObject="emitOutletVal" />
                </div>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>

            </div>
          </div>

          <!-- Select Lady -->
          <div class="field">
            <label for="" class="label">Select Lady</label>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <div>
                <selectsingleUser v-model="champaId" :items="allChampa" itemName="fullname" single
                  :class="{ errorText: errors[0] }" required />
              </div>
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
            </ValidationProvider>
          </div>

          <!-- Working ID -->
          <div class="field">
            <label for="" class="label">Working ID</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <input type="text" class="input" placeholder="Working ID" v-model="workingID"
                  :class="{ errorText: errors[0] }" required />
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>

          <!-- Start Working Date -->
          <div class="grids is-2-desktop gap-20-desktop">
            <div class="field">
              <label for="" class="label">Start Working Date</label>
              <div class="control">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <date-picker v-model="joinDate" type="date" placeholder="Start Working Date"
                    :class="{ errorText: errors[0] }" required>
                    >
                  </date-picker>
                  <span class="error is-error" v-if="errors[0]">
                    required
                  </span>
                </ValidationProvider>
              </div>
            </div>

            <!-- Resigned Date -->
            <div class="field">
              <label for="" class="label">Resigned Date</label>
              <div class="control">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <date-picker :disabled-date="disabledResignedDate" v-model="resignedDate" type="date"
                    placeholder="Resigned Date" :class="{ errorText: errors[0] }" required>
                    >
                  </date-picker>
                  <span class="error is-error" v-if="errors[0]">
                    required
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- Reason of Resignation -->
          <div class="field">
            <label for="" class="label">Reason of Resignation</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <textarea class="textarea" id="" cols="30" rows="5" v-model="reason" :class="{ errorText: errors[0] }"
                  required></textarea>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- button -->
        <div class="card-footer">
          <div class="buttons">
            <button class="button primary" @click="handleSubmit(addRewardHistory)">Save</button>
            <button class="button grey" @click="close()">Cancel</button>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import selectsingleOutlet from "@coms/selectsingle-search.vue";
import selectsingleUser from "@coms/selectsingle-search.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    selectsingleOutlet,
    selectsingleUser,
    DatePicker,
  },
  data: () => ({
    addHistoryFormOpen: false,
    allOutlet: [],
    allChampa: [],
    champaId: "",
    outletId: "",
    joinDate: "",
    resignedDate: "",
    workingID: "",
    reason: ""
  }),
  watch: {
    // joinDate(newValue) {
    //   if (newValue) {
    //     this.resignedDate = this.joinDate;
    //   }
    // },
  },
  created() {
    this.FetchOutlet();
    this.FetchChampa();
  },
  methods: {
    disabledResignedDate(date) {
      return (
        date <=
        new Date(
          new Date(this.joinDate).setDate(
            new Date(this.joinDate).getDate() - 1
          )
        )
      );
    },
    close() {
      this.$emit("addHistoryFormOpen", ["addHistoryFormOpen"]);

    },
    emitOutletVal(val) {
      this.workingID = val.defaultWorkingID
    },
    async FetchOutlet() {
      const res = await this.$axios.get(`${this.$server}api/get-all?typeShop`);
      this.allOutlet = res.data.mapShop;
    },
    async FetchChampa() {
      const res = await this.$axios.get(`${this.$server}api/get-all-user?typeChampa`);
      this.allChampa = res.data.mapUser;
    },
    async addRewardHistory() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      const val = await this.$axios.post(`${this.$server}api/add-backward-history`, {
        shopId: this.outletId,
        userId: this.champaId,
        workingID: this.workingID,
        joinDate: this.joinDate,
        resignedDate: this.resignedDate,
        reason: this.reason
      });

      setTimeout(() => {
        this.$emit("addHistoryFormOpen", ["addHistoryFormOpen", "addedHistory"]);
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
      }, 500);
    }

  },
};
</script>

<style lang="scss" scoped></style>
