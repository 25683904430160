<template>
  <div class="sideform">
    <div class="sideform-header primary">
      <h3>Jan-2022</h3>
    </div>
    <div class="form scrollable-auto">
      <ul class="summary">
        <li>
          <span>Total Expense</span>
          <p>190,400,000</p>
        </li>
        <li>
          <span>Total Employee</span>
          <p>142</p>
        </li>
      </ul>

      <ul class="payroll-list">
        <li>
          <h3>Salary ( ₭ )</h3>
        </li>
        <li>
          <span>Basic Salary</span>
          <p>170,400,000</p>
        </li>
        <li>
          <span>Refill Card</span>
          <p>12,931,154</p>
        </li>
        <li>
          <span>Fuel Incentive</span>
          <p>25,862,308</p>
        </li>
        <li class="minus">
          <span>SSO</span>
          <p>- 5,717,886</p>
        </li>
        <li class="minus">
          <span>Income TAX</span>
          <p>- 874,412.25</p>
        </li>
        <li>
          <span>Net Salary</span>
          <p>187,375,009.00</p>
        </li>
      </ul>

      <ul class="payroll-list">
        <li>
          <h3>Incentive ( ₭ )</h3>
        </li>
        <li>
          <span>KPI + Incentive</span>
          <p>25,862,308</p>
        </li>
        <li>
          <span>Sale Incentive</span>
          <p>25,862,308</p>
        </li>
        <li>
          <span>Net Incentive</span>
          <p>25,862,308</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sideform-header.primary {
  background-color: var(--primary-color);
  color: #fff;
  h3 {
    text-transform: uppercase;
    font-size: var(--sm-font);
  }
}
.summary {
  margin-bottom: 20px;
  li {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-weight: 700;
    color: var(--primary-color);
    span {
      color: var(--primary-color);
      font-weight: normal;
    }
  }
}
.payroll-list {
  margin-bottom: 20px;
  li {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid var(--border-color);
    font-size: var(--sm-font);
    &:last-child {
      font-weight: 700;
      color: var(--primary-color);
    }
    &.minus * {
      color: var(--alert-color);
    }

    h3 {
      font-weight: 700;
      font-size: var(--md-font);
    }
    span {
      color: var(--grey-color);
    }
  }
}
</style>
