var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal-background"}),_c('ValidationObserver',{staticClass:"modal-card",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Add History")]),_c('i',{staticClass:"fa-regular fa-times close",on:{"click":function($event){return _vm.close()}}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Select Outlet")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('selectsingleOutlet',{class:{ errorText: errors[0] },attrs:{"items":_vm.allOutlet,"itemName":"name","single":"","required":""},on:{"valObject":_vm.emitOutletVal},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}})],1),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Select Lady")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('selectsingleUser',{class:{ errorText: errors[0] },attrs:{"items":_vm.allChampa,"itemName":"fullname","single":"","required":""},model:{value:(_vm.champaId),callback:function ($$v) {_vm.champaId=$$v},expression:"champaId"}})],1),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Working ID")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.workingID),expression:"workingID"}],staticClass:"input",class:{ errorText: errors[0] },attrs:{"type":"text","placeholder":"Working ID","required":""},domProps:{"value":(_vm.workingID)},on:{"input":function($event){if($event.target.composing){ return; }_vm.workingID=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"grids is-2-desktop gap-20-desktop"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Start Working Date")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{class:{ errorText: errors[0] },attrs:{"type":"date","placeholder":"Start Working Date","required":""},model:{value:(_vm.joinDate),callback:function ($$v) {_vm.joinDate=$$v},expression:"joinDate"}},[_vm._v(" > ")]),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Resigned Date")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{class:{ errorText: errors[0] },attrs:{"disabled-date":_vm.disabledResignedDate,"type":"date","placeholder":"Resigned Date","required":""},model:{value:(_vm.resignedDate),callback:function ($$v) {_vm.resignedDate=$$v},expression:"resignedDate"}},[_vm._v(" > ")]),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Reason of Resignation")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reason),expression:"reason"}],staticClass:"textarea",class:{ errorText: errors[0] },attrs:{"id":"","cols":"30","rows":"5","required":""},domProps:{"value":(_vm.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reason=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.addRewardHistory)}}},[_vm._v("Save")]),_c('button',{staticClass:"button grey",on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }