<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActive }">
      <div class="page-header" v-if="!formActive">
        <div class="page-header-start">
          <h3>BA - Lady</h3>
          <div class="button-group">
            <button
              class="button primary"
              @click="
                formActive = true;
                $router
                  .push({
                    ...$route,
                    query: {
                      ...$route.query,
                      add: 'add',
                    },
                  })
                  .catch((err) => {});
              "
            >
              Add
            </button>
            <button
              class="button light-grey"
              @click="
                formActiveExport = true;
                $router.push({
                  ...$route,
                  query: {
                    ...$route.query,
                    BA: 'BA',
                  },
                });
              "
            >
              Export
            </button>
          </div>
        </div>

        <div class="page-header-end">
          <input
            v-model="searchChampa"
            type="text"
            class="input"
            placeholder="Search..."
          />
        </div>

        <div class="page-header-end">
          <ul>
            <li>
              <p>All Champa:</p>
              <h3>{{ totalChampaBA }}</h3>
            </li>
            <li>
              <div class="select">
                <supervisor
                  v-model="selectedSupervisor"
                  :data="supervisor"
                  itemName="name"
                />
              </div>
            </li>
            <li>
              <div class="select">
                <supervisor
                  v-model="selectedStatus"
                  :data="status"
                  itemName="name"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th>Working ID</th>
              <th>Champa Status</th>
              <th class="is-xs">Outlet Code</th>
              <th>Outlet Name</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in getChampa" :key="index">
              <td
                class="clickable"
                @click="
                  formActive = true;
                  $router
                    .push({
                      query: {
                        selectedSupervisor: $route.query.selectedSupervisor,
                        selectedStatus: $route.query.selectedStatus,
                        page: $route.query.page,
                        perPage: pages.perPage,
                        searchChampa: $route.query.searchChampa,
                        id: i._id,
                      },
                    })
                    .catch((err) => {});
                "
              >
                {{ i.employeeID }}
              </td>
              <td>{{ i.fullname }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ i.status }}</td>
              <td>{{ i.shopCode }}</td>
              <td>{{ i.shopName }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <paginate v-model="page" :total="all" />
    </div>

    <transition name="slide-fade">
      <component
        v-if="formActive"
        :is="'champaForm'"
        @close="formActive = false"
      ></component>
    </transition>

    <export-date
      v-if="formActiveExport"
      @closeExport="formActiveExport = false"
    />
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import supervisor from "../components/filter/filter.vue";
import champaForm from "../components/form/champa.vue";
import ExportDate from "../components/export/export-date.vue";

export default {
  components: {
    paginate,
    supervisor,
    champaForm,
    ExportDate,
  },
  data: () => ({
    status: [
      {
        _id: "pending",
        name: "Pending",
      },
      {
        _id: "working",
        name: "Working",
      },
      {
        _id: "resigned",
        name: "Resigned",
      },
    ],
    formActive: false,
    formActiveExport: false,
    getChampa: [],
    supervisor: [],
    totalChampaBA: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    selectedSupervisor: "",
    selectedStatus: "",
    searchChampa: "",
  }),
  watch: {
    "$route.query.status": {
      handler(val) {
        if (val === "close") {
          this.formActive = false;
        }
      },
    },
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchChampa();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: {
            ...this.$route.query,
            selectedSupervisor: val,
          },
        });
      },
    },
    //status
    "$route.query.selectedStatus": {
      handler(val) {
        this.FetchChampa();
      },
      immediate: true,
    },
    selectedStatus: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, selectedStatus: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchChampa();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    "$route.query.searchChampa": {
      handler(val) {
        this.FetchChampa();
      },
      immediate: true,
    },
    searchChampa: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, searchChampa: val },
        });
      },
    },
    $route() {
      this.FetchChampa();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchChampa();
    this.FetchSupervisor();
  },
  methods: {
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchChampa() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-user-ba?adminId=${this.$route.query.selectedSupervisor}&status=${this.$route.query.selectedStatus}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchChampa}`
      );
      this.getChampa = res.data.aggreUserBA;
      this.totalChampaBA = res.data.total;
      this.all = res.data.total;
    },
    async ExportLadyBA() {
      try {
        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-BA`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LadyBA.xlsx");
        document.body.appendChild(link);
        link.click();
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
        // this.$emit("closeExport");
        // await this.$store.dispatch("completed")
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 0 10px 0 0;
  .table {
    width: 100%;
  }
  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
