<template>
  <div class="sideform">
    <!-- <div class="sideform-header">
      <ul>
        <li v-if="$route.query.shopId">
          <h3>{{ shopName }}</h3>
        </li>
        <li v-else></li>
      </ul>
    </div> -->

    <div class="page-container">
      <div class="page-content">
        <div class="page-header">
          <div class="page-header-start">
            <h3>Outlet Name: {{ shopName }}</h3>
          </div>
        </div>

        <!-- {{ statusLock }} -->

        <div class="table-container scrollable">
          <table class="table">
            <thead>
              <tr>
                <th class="is-xs">Outlet Activity Date</th>
                <th class="is-xs">Champa Name</th>

                <th class="is-xs text-right">Outlet Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="statusLock.length > 0"
                v-for="(i, index) in statusLock"
                :key="index"
              >
                <td>{{ i.date }}</td>
                <td>{{ i.userId ? i.userId.fullname : "--" }}</td>
                <td class="text-right">{{ i.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="sideform-footer">
      <!-- <button v-if="$route.query.id" class="button primary" @click="UpdateZone">
          Update
        </button>
        <button v-else class="button primary" @click="AddZone">Save</button> -->
      <button class="button grey" @click="close()">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["propFormActive"],
  data: () => ({
    area: {},
    statusLock: [],
    shopId: "",
    shopName: "",
  }),
  watch: {
    "$route.query.shopId": {
      handler(val) {
        this.shopId = val;
        this.FetchStatusLock();
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query.shopId) {
      this.shopId = this.$route.query.shopId;
      // alert(this.shopId)
      this.FetchStatusLock();
    }
  },
  methods: {
    close() {
      this.$emit("closeForm");
      this.$router
        .push({
          query: {
            selectedSupervisor: this.$route.query.selectedSupervisor,
            selectedStatus: this.$route.query.selectedStatus,
            page: this.$route.query.page,
            perPage: 10,
            searchResigned: this.$route.query.searchResigned,
            type: this.$route.query.type,
            champaHistory: this.$route.query.champaHistory,
          },
        })
        .catch((err) => {});
    },

    async FetchStatusLock() {
      const res = await this.$axios.get(
        `${this.$server}api/get-statuslock?shopId=${this.shopId}`
      );
      this.statusLock = res.data.mapStatusLock;
      this.shopName =
        this.statusLock.length > 0 ? this.statusLock[0]["shopName"] : "";
    },

    // FetchZoneId() {
    //   this.$axios
    //     .get(`${this.$server}api/zone-findid/` + this.$route.query.id)
    //     .then((res) => {
    //       this.area = res.data.mapIdZone;
    //       (this.new_ZoneCode = this.area.zoneCode),
    //         (this.new_Name = this.area.name),
    //         (this.newCode = this.area.code),
    //         (this.newDE = this.area.DE),
    //         (this.newASM = this.area.ASM);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    font-size: var(--xsm-font) !important;
  }
}
</style>
