<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    class="modal is-active"
    tag="div"
  >
    <div class="modal-background" @click="close"></div>
    <div class="modal-card">
      <div class="card-header">
        <h3 v-if="$route.query.statusDate === 'JoinDate'">Update JoinDate</h3>
        <h3 v-else>Update Resigned Date</h3>
      </div>

      <div class="card-body">
        <!-- Datepicker -->
        <div class="field">
          <div class="control">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <date-picker
                type="date"
                v-model="date"
                placeholder="Select Date"
                :class="{ errorText: errors[0] }"
                required
              ></date-picker>
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="buttons">
          <!-- export champa -->
          <button class="button primary" @click="handleSubmit(SaveDate)">
            Save
          </button>
          <!-- <button class="button primary" @click="SaveDate()">
                        Save
                    </button> -->

          <button class="button light-grey" @click="close()">Cancel</button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  props: {
    propsDate: {
      type: String,
    },
  },
  data: () => ({
    date: "",
  }),
  components: {
    DatePicker,
  },
  mounted() {
    this.date = new Date(this.propsDate);
  },
  methods: {
    close() {
      this.$emit("close");
    },
    SaveDate() {
      this.$router
        .push({
          ...this.$route,
          query: { ...this.$route.query, status: "Success" },
        })
        .catch((err) => {});

      setTimeout(() => {
        this.$emit("emitDate", this.date);
      }, 300);
    },
  },
};
</script>

<style lang="scss"></style>
