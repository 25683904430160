<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActive }">
      <div class="page-header" v-if="!formActive">
        <div class="page-header-start">
          <h3 v-if="$route.query.type === 'champa'">Champa - Lady</h3>
          <h3 v-else>BA - Lady</h3>
          <div class="button-group">
            <button class="button primary sm" @click="
              formActive = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  add: 'add',
                },
              })
              .catch((err) => { });
            ">
              Add
            </button>

            <!-- Single Date Champa Active -->
            <!-- <button
              v-if="$route.query.type === 'champa'"
              class="button light-grey sm"
              @click="
                formActiveExport = true;
                $router
                  .push({
                    ...$route,
                    query: {
                      ...$route.query,
                      champa: 'champa',
                    },
                  })
                  .catch((err) => {});
              "
            >
              Export Champa Working
            </button>

            <button
              v-else
              class="button light-grey sm"
              @click="
                formActiveExport = true;
                $router
                  .push({
                    ...$route,
                    query: {
                      ...$route.query,
                      BA: 'BA',
                    },
                  })
                  .catch((err) => {});
              "
            >
              Export BA Working
            </button> -->

            <!-- Multi Date Champa -->
            <button v-if="$route.query.type === 'champa'" class="button light-grey sm" @click="
              formActiveExportMultiple = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  champa: 'champa',
                },
              })
              .catch((err) => { });
            ">
              ດຶງຂໍ້ມູນໄລ່ເງິນເດືອນ
            </button>
            <button v-else class="button light-grey" @click="
              formActiveExportMultiple = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  BA: 'BA',
                },
              })
              .catch((err) => { });
            ">
              ດຶງຂໍ້ມູນໄລ່ເງິນເດືອນ
            </button>
          </div>
        </div>

        <div class="page-header-end">
          <input v-model="searchChampa" type="text" class="input" placeholder="Search..." />
        </div>

        <div class="page-header-end">
          <ul>
            <li v-if="isLoading" class="loading_nav"></li>
            <li v-else>
              <p>All Champa:</p>
              <h3>{{ totalChampa }}</h3>
            </li>
            <li>
              <div class="select">
                <filterSelect v-model="selectedSupervisor" :data="supervisor" itemName="name" optionName="Supervisor" />
              </div>
            </li>
            <li>
              <div class="select">
                <filterSelect v-model="selectedStatus" :data="status" itemName="name" optionName="Status" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="isLoading" class="table-container scrollable">
        <div v-for="(l, indexLoading) in getChampa" :key="indexLoading" class="loading"></div>
      </div>
      <div v-else class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th>Working ID</th>
              <th>Champa Status</th>
              <th class="is-xs">Outlet Code</th>
              <th>Outlet Name</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in getChampa" :key="index">
              <td class="clickable" @click="
                formActive = true;
              $router
                .push({
                  query: {
                    selectedSupervisor: $route.query.selectedSupervisor,
                    selectedStatus: $route.query.selectedStatus,
                    page: $route.query.page,
                    perPage: pages.perPage,
                    searchChampa: $route.query.searchChampa,
                    type: $route.query.type,
                    id: i._id,
                  },
                })
                .catch((err) => { });
              ">
                {{ i.employeeID }}
              </td>
              <td>{{ i.fullname }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ i.status }}</td>
              <td>{{ i.shopCode }}</td>
              <td>{{ i.shopName }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate v-model="page" :total="all" />
    </div>

    <transition name="slide-fade">
      <component v-if="formActive" :is="'champaForm'" @close="formActive = false"></component>
    </transition>

    <export-date v-if="formActiveExport" @closeExport="formActiveExport = false" />
    <ExportDateMultiple v-if="formActiveExportMultiple" @closeExport="formActiveExportMultiple = false" />
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import filterSelect from "../components/filter/filter.vue";
import champaForm from "../components/form/champa.vue";
import ExportDateMultiple from "../components/export/multi-export-date.vue";
import ExportDate from "../components/export/export-date.vue";

export default {
  components: {
    paginate,
    filterSelect,
    champaForm,
    ExportDate,
    ExportDateMultiple,
  },
  data: () => ({
    status: [
      {
        _id: "pending",
        name: "Pending",
      },
      {
        _id: "working",
        name: "Working",
      },
      {
        _id: "resigned",
        name: "Resigned",
      },
    ],
    isLoading: false,
    formActive: false,
    formActiveExport: false,
    formActiveExportMultiple: false,

    getChampa: [],
    supervisor: [],
    totalChampa: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    selectedSupervisor: "",
    selectedStatus: "",
    searchChampa: "",
    type: "",
    timeout: null
  }),
  watch: {
    "$route.query.status": {
      handler(val) {
        if (val === "close") {
          this.formActive = false;
        }
      },
    },
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchChampa();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: {
            ...this.$route.query,
            selectedSupervisor: val,
          },
        });
      },
    },
    //status
    "$route.query.selectedStatus": {
      handler(val) {
        this.FetchChampa();
      },
      immediate: true,
    },
    selectedStatus: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, selectedStatus: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchChampa();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },


    searchChampa(newValue) {
      // handler(val) {
      //   this.isLoading = true;
      //   setTimeout(() => {
      //     this.$router.push({
      //       ...this.$route,
      //       query: { ...this.$route.query, searchChampa: this.searchChampa },
      //     }).catch((err) => { });

      //     setTimeout(() => {
      //       this.isLoading = false;
      //     }, 1000)
      //   }, 5000)
      // },


      this.isLoading = true;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, searchChampa: newValue },
        }).catch((err) => { });

        setTimeout(() => {
          this.isLoading = false;
        }, 1000)
      }, 3000)

    },

    //search
    // "$route.query.searchChampa": {
    //   handler(val) {
    //     this.FetchChampa();
    //     this.isLoading = false;

    //   },
    //   immediate: true,
    // },

    //type
    "$route.query.type": {
      handler(val) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.FetchChampa();
        }, this.$timeout);
      },
      immediate: true,
    },
    type: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, type: val },
        });
      },
    },
    $route() {
      this.FetchChampa();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchChampa();
    this.FetchSupervisor();
  },
  methods: {
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchChampa() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-user-champa?adminId=${this.$route.query.selectedSupervisor}&status=${this.$route.query.selectedStatus}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchChampa}&typeChampa=${this.$route.query.type}`
      );
      this.getChampa = res.data.aggreUserChampa;
      this.totalChampa = res.data.total;
      this.all = res.data.total;
    },
    async ExportLadyChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-champa`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ChampaLady.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          // this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
    async ExportLadyBA() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-BA`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "BaLady.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          // this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 0 10px 0 0;

  .table {
    width: 100%;
  }

  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
