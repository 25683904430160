<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="page-header-start">
          <h3>BA - History</h3>
        </div>

        <div class="page-header-end">
          <input
            v-model="searchResigned"
            type="text"
            class="input"
            placeholder="Search..."
          />
        </div>

        <div class="page-header-end">
          <ul>
            <li>
              <p>All Champa:</p>
              <h3>{{ totalChampa }}</h3>
            </li>
            <li>
              <div class="select">
                <supervisor
                  v-model="selectedSupervisor"
                  :data="supervisor"
                  itemName="name"
                />
              </div>
            </li>
            <!-- <li>
              <div class="select">
                <supervisor v-model="selectedStatus" :data="status" />
              </div>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th>Working ID</th>
              <th>Join Date</th>
              <th>Resign Date</th>
              <th class="is-xs">Outlet Code</th>
              <th>Outlet Name</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in BAResigned" :key="index">
              <td>{{ i.employeeID }}</td>
              <td>{{ i.fullname }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ getMoment(i.joinDate) }}</td>
              <td>{{ getMoment(i.resignedDate) }}</td>
              <td>{{ i.shopCode }}</td>
              <td>{{ i.shopName }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate v-model="page" :total="all" />
    </div>
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import supervisor from "../components/filter/filter.vue";
import moment from "moment";
export default {
  components: {
    paginate,
    supervisor,
  },
  data: () => ({
    status: [
      {
        _id: true,
        name: "Working",
      },
      {
        _id: false,
        name: "Resigned",
      },
    ],
    BAResigned: [],
    supervisor: [],
    selectedSupervisor: "",
    selectedStatus: "",
    searchResigned: "",
    totalChampa: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
  }),
  watch: {
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchBAResigned();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: {
            ...this.$route.query,
            selectedSupervisor: val,
          },
        });
      },
    },
    //status
    // "$route.query.selectedStatus": {
    //   handler(val) {
    //     this.FetchBAResigned();
    //   },
    //   immediate: true,
    // },
    // selectedStatus: {
    //   handler(val) {
    //     this.page = 1;
    //     this.$router.push({
    //       ...this.$route,
    //       query: { ...this.$route.query, selectedStatus: val },
    //     });
    //   },
    // },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchBAResigned();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    "$route.query.searchResigned": {
      handler(val) {
        this.FetchBAResigned();
      },
      immediate: true,
    },
    searchResigned: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, searchResigned: val },
        });
      },
    },
  },
  computed: {
    getMoment() {
      return (val) => {
        if (val) return moment(val).format("YYYY-MM-DD");
        return val;
      };
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchSupervisor();
    this.FetchBAResigned();
  },
  methods: {
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchBAResigned() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-ba-resigned?admin=${this.$route.query.selectedSupervisor}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchResigned}`
      );
      this.BAResigned = res.data.aggreShop;
      this.totalChampa = res.data.total;
      this.all = res.data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 0 10px 0 0;
  .table {
    width: 100%;
  }
  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
