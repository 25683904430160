var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-content",class:{ small: _vm.formActiveHistory }},[(_vm.addHistoryFormOpen)?_c('AddHistory',{on:{"addHistoryFormOpen":_vm.emitFormAddedHistory}}):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.formDailyReport)?_c('DailyReportForm',{attrs:{"champaResigned":_vm.propsChampaResigned},on:{"close":function($event){_vm.formDailyReport = false}}}):_vm._e()],1),(!_vm.formActiveHistory)?_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header-start"},[(_vm.$route.query.type === 'champa')?_c('h3',[_vm._v("Champa - History")]):_c('h3',[_vm._v("BA - History")]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary sm",on:{"click":function($event){return _vm.addNewHistory()}}},[_vm._v(" Add History ")]),(_vm.$route.query.type === 'champa')?_c('button',{staticClass:"button light-grey sm",on:{"click":function($event){_vm.formActiveExport = true;
          _vm.$router
            .push(Object.assign({}, _vm.$route,
              {query: Object.assign({}, _vm.$route.query,
                {champa: 'champa'})}))
            .catch(function (err) { });}}},[_vm._v(" Export Champa History ")]):_c('button',{staticClass:"button light-grey sm",on:{"click":function($event){_vm.formActiveExport = true;
          _vm.$router
            .push(Object.assign({}, _vm.$route,
              {query: Object.assign({}, _vm.$route.query,
                {BA: 'BA'})}))
            .catch(function (err) { });}}},[_vm._v(" Export BA History ")]),_c('button',{staticClass:"button light-grey sm",on:{"click":_vm.ExportOutletHistory}},[_vm._v(" Export All Outlet History ")])])]),_c('div',{staticClass:"page-header-end"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchResigned),expression:"searchResigned"}],staticClass:"input",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.searchResigned)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchResigned=$event.target.value}}})]),_c('div',{staticClass:"page-header-end"},[_c('ul',[(_vm.isLoading)?_c('li',{staticClass:"loading_nav"}):_c('li',[_c('p',[_vm._v("All Champa:")]),_c('h3',[_vm._v(_vm._s(_vm.totalChampa))])]),_c('li',[_c('div',{staticClass:"select"},[_c('filterSelect',{attrs:{"data":_vm.supervisor,"itemName":"name","optionName":"Supervisor"},model:{value:(_vm.selectedSupervisor),callback:function ($$v) {_vm.selectedSupervisor=$$v},expression:"selectedSupervisor"}})],1)])])])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"table-container scrollable"},_vm._l((_vm.champaResigned),function(l,indexLoading){return _c('div',{key:indexLoading,staticClass:"loading"})}),0):_c('div',{staticClass:"table-container scrollable"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.champaResigned),function(i,index){return _c('tr',{key:index},[_c('td',{staticClass:"clickable",on:{"click":function($event){_vm.formActiveHistory = true;
            _vm.$router.push({
              query: {
                selectedSupervisor: _vm.$route.query.selectedSupervisor,
                selectedStatus: _vm.$route.query.selectedStatus,
                page: _vm.$route.query.page,
                perPage: _vm.pages.perPage,
                searchResigned: _vm.$route.query.searchResigned,
                type: _vm.$route.query.type,
                champaHistory: _vm.$route.query.champaHistory,
                historyId: i._id,
              }
            })
              .catch(function (err) { });}}},[_vm._v(" "+_vm._s(i.employeeID)+" ")]),_c('td',[_vm._v(_vm._s(i.fullname))]),_c('td',{staticClass:"has-edit"},[(!_vm.editWorkingToggle)?_c('a',{staticClass:"clickableSomefield",on:{"click":function($event){return _vm.ClickWorkingID(i._id, i, index)}}},[_vm._v(" "+_vm._s(i.workingID)+" "),_c('i',{staticClass:"fa-solid fa-pen"})]):(_vm.indValue === index)?_c('div',{staticClass:"input-toggle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(i.workingID),expression:"i.workingID"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(i.workingID)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(i, "workingID", $event.target.value)}}}),_c('i',{staticClass:"fa-solid fa-floppy-disk",on:{"click":function($event){return _vm.UpdateWorkingIDHistory()}}})]):_c('a',{staticClass:"clickableSomefield"},[_vm._v(" "+_vm._s(i.workingID)+" ")])]),_c('td',[_c('a',{staticClass:"clickableSomefield",on:{"click":function($event){return _vm.ClickPropSingleDatePicker(i.joinDate, i._id, 'JoinDate')}}},[_vm._v(" "+_vm._s(_vm.getMoment(i.joinDate))+" "),_c('i',{staticClass:"fa-solid fa-pen"})])]),_c('td',[_c('a',{staticClass:"clickableSomefield",on:{"click":function($event){return _vm.ClickPropSingleDatePicker(
                  i.resignedDate,
                  i._id,
                  'ResignedDate'
                )}}},[_vm._v(" "+_vm._s(_vm.getMoment(i.resignedDate))+" "),_c('i',{staticClass:"fa-solid fa-pen"})])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(i.shopCode))]),_c('td',[_vm._v(" "+_vm._s(i.shopName)+" ")]),_c('td',[_c('a',{staticClass:"clickableSomefield",on:{"click":function($event){return _vm.clickFormDailyReport(i)}}},[_c('i',{staticClass:"fa-solid fa-plus"}),_vm._v(" Daily Report ")])]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(i.adminName))])])}),0)]),(_vm.isSingleDatePicker)?_c('SingleDatePicker',{attrs:{"propsDate":_vm.date},on:{"close":_vm.emitCloseSingleDatePicker,"emitDate":_vm.emitSingleDatePicker}}):_vm._e()],1),_c('paginate',{attrs:{"total":_vm.all},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.formActiveHistory)?_c('HistoryForm',{on:{"closeFormHistory":function($event){_vm.formActiveHistory = false}}}):_vm._e()],1),(_vm.formActiveExport)?_c('export-date',{on:{"closeExport":function($event){_vm.formActiveExport = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"is-xs"},[_vm._v("Employee ID")]),_c('th',{staticClass:"is-xs "},[_vm._v("Champa Name")]),_c('th',{},[_vm._v("Working ID")]),_c('th',{},[_vm._v("Join Date")]),_c('th',{},[_vm._v("Resigned Date")]),_c('th',{staticClass:"is-xs text-center"},[_vm._v("Outlet Code")]),_c('th',[_vm._v("Outlet Name")]),_c('th',[_vm._v("DailyReport")]),_c('th',{staticClass:"is-xs text-right"},[_vm._v("Supervisor")])])])}]

export { render, staticRenderFns }