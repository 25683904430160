<template>
    <div class="sideform">
        <div class="sideform-header">
            <!-- <h3>Update Reason</h3>
            <button class="button" @click="close">Cancel</button> -->


            <div class="page-header">
                <!-- {{ user._id }} -->

                <div class="page-header-start">
                    <h3>Update Reason</h3>
                </div>
                <div class="page-header-end" v-if="user._id === '61e24a38eb9b8b05580c6765' || user._id === '635b8c5df252a3272a3bd025' || user._id === '61e24a51eb9b8b05580c676b'">
                    <button class="button" @click="DeleteHistory($route.query.historyId)">Delete History</button>
                </div>
            </div>
        </div>

        <div class="form scrollable-auto">

            <!-- Champa Name -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Champa Name</label>
                        <div class="control">
                            <input v-model="newChampaName" type="text" class="input" :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!--  WorkingID -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">WorkingID</label>
                        <div class="control">
                            <input v-model="newWorkingID" type="text" class="input" :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!--  JoinDate -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Join Date</label>
                        <div class="control">
                            <input v-model="newJoinDate" type="text" class="input" />
                            <!-- <date-picker v-model="newJoinDate" type="date" :disabled="true">
                            </date-picker> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- ResignedDate -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Resigned Date</label>
                        <div class="control">
                            <input v-model="newResignedDate" type="text" class="input" />
                            <!-- <date-picker v-model="newResignedDate" type="date" :disabled="true">
                            </date-picker> -->
                        </div>
                    </div>
                </div>
            </div>
            <!--  Outlet Code -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Outlet Code</label>
                        <div class="control">
                            <input v-model="newOutletCode" type="text" class="input" :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Outlet Name -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Outlet Name</label>
                        <div class="control">
                            <input v-model="newOutletName" type="text" class="input" :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Admin Name -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Admin Name</label>
                        <div class="control">
                            <input v-model="newAdminName" type="text" class="input" :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Reason -->
            <div class="grids is-1-desktop gap-10-desktop">
                <div class="column is-12">
                    <div class="field">
                        <label for="" class="label">Reason</label>
                        <div class="control">
                            <!-- <input v-model="newFreeText" type="text" class="input" /> -->
                            <textarea class="textarea" rows="5" v-model="newReason"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="sideform-footer">
            <button class="button primary" @click="UpdateReasonHistory()">
                Update
            </button>
            <button class="button grey" @click="close">Cancel</button>

            <!-- <button class="button primary" @click="ClickFormReportShop()">Report</button> -->
        </div>
    </div>
</template>
  
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    data: () => ({
        mapHistory: {},
        newChampaName: "",
        newWorkingID: "",
        newJoinDate: "",
        newResignedDate: "",
        newOutletCode: "",
        newOutletName: "",
        newAdminName: "",
        newReason: "",
        user: {},

    }),
    components: {
        DatePicker,
    },
    computed: {
        getMoment() {
            return (val) => {
                if (val) return moment(val).format("DD-MMM-YY");
            };
        },
    },
    watch: {
        "$route.query.historyId": {
            handler() {
                if (this.$route.query.historyId) {
                    this.FetchHistoryId();
                }
            },
            immediate: true,
        },
    },
    created() {
        this.FetchAdminGetter();
        // this.FetchHistoryId();
    },
    methods: {
        close() {
            this.$emit("closeFormHistory");
            this.$router
                .push({
                    query: {
                        selectedSupervisor: this.$route.query.selectedSupervisor,
                        selectedStatus: this.$route.query.selectedStatus,
                        page: this.$route.query.page,
                        perPage: 10,
                        searchResigned: this.$route.query.searchResigned,
                        type: this.$route.query.type,
                        champaHistory: this.$route.query.champaHistory,
                    },
                })
                .catch((err) => { });
        },
        async FetchHistoryId() {
            const res = await this.$axios.get(
                `${this.$server}api/history-find-id/` + this.$route.query.historyId
            );
            this.mapHistory = res.data.mapHistory;
            this.newChampaName = this.mapHistory.fullname;
            this.newWorkingID = this.mapHistory.workingID;
            this.newJoinDate = this.mapHistory.joinDate;
            this.newResignedDate = this.mapHistory.resignedDate;
            this.newOutletCode = this.mapHistory.shopCode;
            this.newOutletName = this.mapHistory.name;
            this.newAdminName = this.mapHistory.adminName;
            this.newReason = this.mapHistory.reason;
        },

        async UpdateReasonHistory() {
            try {
                //
                //Alert Loading
                this.$swal({
                    title: "Loading...",
                    html: "Please wait",
                    timerProgressBar: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                })

                const updateForm = {
                    id: this.$route.query.historyId,
                    reason: this.newReason,
                };
                await this.$axios
                    .put(`${this.$server}api/history-update-workingID`, updateForm).then((res) => {
                        setTimeout(() => {
                            this.close();
                            this.$swal({
                                position: "top",
                                icon: "success",
                                title: "Success",
                                showConfirmButton: false,
                                timer: 1000,
                            });
                        }, 500);
                    })
            } catch (error) {
            }
        },
        async DeleteHistory(id) {
            await this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "confirm",
                cancelButtonText: "cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$axios.delete(`${this.$server}api/history-delete/${id}`);
                    this.close();
                    this.$swal({
                        position: "top",
                        icon: "success",
                        title: "Success",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                }
            })
        },
        async FetchAdminGetter() {
            this.$axios.defaults.headers[
                "Authorization"
            ] = this.$store.getters.getToken;
            this.$axios
                .get(`${this.$server}api/admin-find-id`)
                .then((response) => {
                    this.user = response.data.findId;
                })
        }

    },
};
</script>
  
<style lang="scss" scoped>
.page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    transition: all ease-in-out 0.19s;

    .page-header-start {
        display: flex;
        align-items: center;
    }

    .page-header-end {
        display: flex;
        align-items: center;

        p {
            margin-right: 10px;
            white-space: nowrap;
        }

        ul {
            display: flex;
            align-items: center;

            li {
                display: flex;
                align-items: center;
                margin-left: 10px;

                h3 {
                    color: var(--primary-color);
                }
            }
        }
    }
}

.input {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .selected-item {
        padding: 0;
    }

    .selected-item:hover {
        cursor: pointer;
        background: var(--grey-color);
        color: var(--bg-light);
    }

    .input-group {
        .inputs {
            //   border: none;
            position: absolute;
            bottom: 0px;
            height: 100%;
            width: 100%;
            border: none;
        }

        .selects {
            position: absolute;
            display: block;
            top: 42px;
            left: 0;
            z-index: 9999;
            background-color: var(--bg-light);
            width: 100%;
            height: 150px;
            overflow-y: scroll;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;

            .select-item {
                padding: 2px 10px;
            }

            .select-item:hover {
                cursor: pointer;
                color: var(--bg-color);
                background-color: var(--primary-color);
            }
        }
    }
}

li a {
    padding: 5px 10px;
    color: #fff;
    margin-left: 10px;
    border-radius: 5px;
    font-size: var(--xsm-font);
    background-color: var(--alert-color);
}

span {
    cursor: pointer;
}
</style>
  