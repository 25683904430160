<template>
  <ValidationObserver v-slot="{ handleSubmit }" class="modal is-active" tag="div">
    <div class="modal-background" @click="close"></div>
    <div class="modal-card">
      <div class="card-header">
        <h3><i class="fa-solid fa-download"></i>ດຶງຂໍ້ມູນສຳຫຼັບໄລ່ເງິນເດືອນ</h3>
        <p>
          ເປັນການດຶງຂໍ້ມູນສາວຈຳປາທີ່ມີສະຖານນະ
          <b>ເຮັດວຽກ / Working</b> ແລະ
          ສາວຈຳປາທີ່ໄດ້ອອກວຽກໃນຊ່ວງເວລາທີ່ກຳໜົດດ້ານລຸ່ມ
        </p>
      </div>

      <div class="card-body">
        <div class="grids is-2-desktop gap-20-desktop">
          <div class="field no-margin">
            <!-- <label for="" class="label">Start Date</label> -->
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <date-picker type="date" v-model="startDate" placeholder="Select Start Date"
                  :class="{ errorText: errors[0] }" required></date-picker>
                <span class="error is-error" v-if="errors[0]">
                  please select start date
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="field ">
            <!-- <label for="" class="label">End Date</label> -->
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <date-picker type="date" v-model="endDate" placeholder="Select End Date" :class="{ errorText: errors[0] }"
                  required></date-picker>
                <span class="error is-error" v-if="errors[0]">
                  please select end date
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="buttons">
          <!-- export champa -->
          <button class="button primary" v-if="$route.query.champa" @click="handleSubmit(ExportChampa)">
            Export
          </button>
          <!-- export BA -->
          <button class="button primary" v-if="$route.query.BA" @click="handleSubmit(ExportBA)">
            Export
          </button>
          <button class="button light-grey" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  name: "multi-export-date",
  data: () => ({
    startDate: "",
    endDate: "",
    errorMessage: "",
  }),
  components: {
    DatePicker,
  },
  watch: {
    "$route.query.startDate": {
      handler(val) { },
      immediate: true,
    },
    startDate: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, startDate: val },
        });
      },
    },
    "$route.query.endDate": {
      handler(val) { },
      immediate: true,
    },
    endDate: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, endDate: val },
        });
      },
    },
  },
  methods: {
    close() {
      this.$emit("closeExport");
      this.$router
        .push({
          query: {
            selectedSupervisor: this.$route.query.selectedSupervisor,
            selectedStatus: this.$route.query.selectedStatus,
            page: this.$route.query.page,
            perPage: 10,
            searchChampa: this.$route.query.searchChampa,
            type: this.$route.query.type,
          },
        })
        .catch((err) => { });
    },
    async ExportChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;

        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-excel-champa-date?startDate=${startDate ??
          ""}&endDate=${endDate ?? ""}`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ChampaLady.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
    async ExportBA() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-excel-ba-date?startDate=${startDate ??
          ""}&endDate=${endDate ?? ""}`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "BaLady.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
